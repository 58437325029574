import { useLocation as UseLocation } from "react-router-dom";
import getCustomComponent from "./getCustomComponent";
import { MODULES } from "constants/Constants";
import { useContext } from "react";
import { AuthWizardContext } from "context/Context";

const getInitializedFilters = (filterFields, setFilterFields, module) => {
  const location = UseLocation();
  const actualPath = location.pathname?.split("/")[1];
  const accessLocation = module ?? actualPath;

  const IsCustomer = () => {
    let user = useContext(AuthWizardContext);
    let loggedUsCustomer = user?.user?.customer_id ? true : false;
    return loggedUsCustomer;
  };

  const handleFieldChange = (e, action) => {
    const newFormData = { ...filterFields };
    if (!action) {
      if (e.target.type === "checkbox") {
        if (e.target.name === "is_company") {
          if (e.target.checked) {
            newFormData.is_company = 1;
            newFormData.is_individual = 0;
          } else {
            newFormData.is_company = 0;
          }
        } else if (e.target.name === "is_individual") {
          if (e.target.checked) {
            newFormData.is_individual = 1;
            newFormData.is_company = 0;
          } else {
            newFormData.is_individual = 0;
          }
        } else {
          newFormData[e.target.name] = e.target.checked ? 1 : 0;
        }
      } else {
        newFormData[e.target.name] = e.target.value;
      }
    } else {
      const { name, action: actionType } = action;
      if (actionType === "select-option") {
        if (typeof e === "object" && e.hasOwnProperty("value")) {
          newFormData[name] = e.value;
        } else {
          newFormData[name] = e.map((item) => item.value);
        }
        newFormData[name + "_ref"] = e;
      } else if (actionType === "clear") {
        newFormData[name] = [];
        newFormData[name + "_ref"] = [];
      } else if (actionType === "remove-value") {
        newFormData[name] = e.map((item) => item.value);
        newFormData[name + "_ref"] = e;
      }
    }

    setFilterFields(newFormData);
  };

  const filtersConfig = {
    [MODULES.EMPLOYEE]: [
      { label: "Name", name: "full_name", type: "text" },
      {
        label: "Designation",
        type: "custom-select",
        name: "designation_id",
        componentKey: "designation",
      },
      {
        label: "Department",
        name: "department_id",
        type: "custom-select",
        componentKey: "department",
      },
      { label: "Role", type: "custom-select", componentKey: "role_id" },
      {
        label: "Status",
        name: "status",
        type: "custom-select",
        componentKey: "common_select",
        options: [
          { label: "Active", value: "Active" },
          { label: "Inactive", value: "Inactive" },
        ],
      },
    ],
    [MODULES.LEAD]: [
      {
        label: "Date Field",
        name: "date_range_for",
        type: "select",
        options: [
          { value: "", label: "Select" },
          { value: "generated_on", label: "Generated On" },
          { value: "created_at", label: "Created At" },
        ],
      },
      {
        label: "Date Range",
        type: "date-range",
        options: [
          { value: "start_date", label: "Start Date" },
          { value: "end_date", label: "End Date" },
        ],
      },
      { label: "Reference", name: "reference", type: "text" },
      { label: "Title", name: "title", type: "text" },
      { label: "Company", name: "company_name", type: "text" },
      {
        label: "Lead Owner",
        name: "lead_owner",
        type: "custom-select",
        componentKey: "users",
      },
      {
        label: "Converted",
        name: "is_converted",
        type: "custom-select",
        componentKey: "common_select",
        options: [
          { label: "Converted", value: 1 },
          { label: "Not Converted", value: 0 },
        ],
      },
      {
        label: "Lead Source",
        type: "custom-select",
        componentKey: "lead_source",
      },
      {
        label: "Priority",
        type: "custom-select",
        componentKey: "priority",
        fetchUrl: "crm/lead-priorities",
      },
      { label: "Status", type: "custom-select", componentKey: "lead_status" },
      {
        label: "Company",
        type: "check-box",
        name: "is_company",
        inactive: "",
        columnWidth: 1,
      },
      {
        label: "Individual",
        type: "check-box",
        name: "is_individual",
        inactive: "",
        columnWidth: 1,
      },
    ],
    [MODULES.CAMPAIGN]: [
      {
        label: "Date Field",
        name: "date_range_for",
        type: "select",
        options: [
          { value: "", label: "Select" },
          { value: "start_date", label: "Start Date" },
          { value: "end_date", label: "End Date" },
        ],
      },
      {
        label: "Date Range",
        type: "date-range",
        options: [
          { value: "start_date", label: "Start Date" },
          { value: "end_date", label: "End Date" },
        ],
      },
      { label: "Name", name: "name", type: "text" },
      {
        label: "Type",
        type: "custom-select",
        name: "type",
        componentKey: "camapign_type",
      },
      { label: "Sponsor", name: "sponsor", type: "text" },
      {
        label: "Product",
        name: "product_id",
        type: "custom-select",
        componentKey: "product",
      },
      {
        label: "Assigned To",
        name: "assigned_to",
        type: "custom-select",
        componentKey: "users",
      },
      { label: "Location", name: "location", type: "text" },
      {
        label: "Status",
        name: "status",
        type: "custom-select",
        componentKey: "campaign_status",
      },
    ],
    [MODULES.OPPORTUNITY]: [
      {
        label: "Date Field",
        name: "date_range_for",
        type: "custom-select",
        componentKey: "common_select",
        options: [
          { value: "opportunity_created_at", label: "Created Date" },
          { value: "opening_date", label: "Opening Date" },
          { value: "closing_date", label: "Closing Date" },
        ],
      },
      {
        label: "Date Range",
        type: "date-range",
        options: [
          { value: "start_date", label: "Start Date" },
          { value: "end_date", label: "End Date" },
        ],
      },
      { label: "Title", name: "title", type: "text" },
      {
        label: "Type",
        name: "type",
        type: "custom-select",
        componentKey: "opportunity_type",
      },
      {
        label: "Account",
        name: "customer_id",
        type: "custom-select",
        componentKey: "account",
      },
      {
        label: "Stage",
        name: "stage",
        type: "custom-select",
        componentKey: "opportunity_stage",
      },
      {
        label: "Lead",
        name: "lead_id",
        type: "custom-select",
        componentKey: "lead",
      },
      {
        label: "Campaign",
        name: "campaign_id",
        type: "custom-select",
        componentKey: "campaign",
      },
      {
        label: "Reference",
        type: "text",
        name: "reference",
      },
    ],
    [MODULES.TODO]: [
      {
        label: "Date Range",
        type: "date-range",
        options: [
          { value: "from_date", label: "From Date" },
          { value: "to_date", label: "To Date" },
        ],
      },
      {
        label: "Title",
        type: "text",
        name: "title",
      },
      {
        label: "Status",
        name: "status",
        type: "custom-select",
        componentKey: "todo_status",
      },
      {
        label: "Team",
        name: "team_id",
        type: "custom-select",
        componentKey: "teams",
      },
    ],
    [MODULES.ACCOUNT]: [
      {
        label: "Account",
        name: "customer_id",
        type: "custom-select",
        componentKey: "account",
      },
      {
        label: "Account ID",
        type: "text",
        name: "customer_code",
      },
      {
        label: "Type",
        name: "customer_type",
        type: "custom-select",
        componentKey: "common_select",
        options: [
          { label: "Company", value: "COMPANY" },
          { label: "Individual", value: "INDIVIDUAL" },
        ],
      },
      {
        label: "Currency",
        name: "currency_code",
        type: "custom-select",
        componentKey: "currency",
      },
      {
        label: "Status",
        name: "status",
        type: "custom-select",
        componentKey: "common_select",
        options: [
          { label: "Active", value: 0 },
          { label: "Inactive", value: 1 },
        ],
      },
    ],
    [MODULES.PROJECT]: [
      {
        label: "Date Field",
        name: "date_range_for",
        type: "custom-select",
        componentKey: "common_select",
        options: [
          { value: "start_date", label: "Start Date" },
          { value: "end_date", label: "End Date" },
        ],
      },
      {
        label: "Date Range",
        type: "date-range",
        options: [
          { value: "start_date", label: "Start Date" },
          { value: "end_date", label: "End Date" },
        ],
      },
      {
        label: "Project",
        name: "project_id",
        type: "custom-select",
        componentKey: "project",
      },
      {
        label: "Client",
        name: "account_id",
        type: "custom-select",
        componentKey: "account",
        inactive: IsCustomer() || actualPath === MODULES.ACCOUNT,
      },
      {
        label: "Status",
        name: "status",
        type: "custom-select",
        componentKey: "project_status",
      },
      {
        label: "Products",
        name: "product_id",
        type: "custom-select",
        componentKey: "product_multi_check",
      },
    ],
    [MODULES.SPRINT]: [
      {
        label: "Date Field",
        name: "date_range_for",
        type: "custom-select",
        componentKey: "common_select",
        options: [
          { value: "start_date", label: "Start Date" },
          { value: "end_date", label: "End Date" },
        ],
      },
      {
        label: "Date Range",
        type: "date-range",
        options: [
          { value: "start_date", label: "Start Date" },
          { value: "end_date", label: "End Date" },
        ],
      },
      {
        label: "Name",
        type: "text",
        name: "name",
      },
      {
        label: "Project",
        name: "project_id",
        type: "custom-select",
        componentKey: "project",
        inactive: actualPath === MODULES.PROJECT,
      },
      {
        label: "Status",
        name: "status",
        type: "custom-select",
        componentKey: "sprint_status_multi",
      },
    ],
    [MODULES.TASKS]: [
      {
        label: "Date Field",
        name: "date_range_for",
        type: "custom-select",
        componentKey: "common_select",
        options: [
          { value: "start_date", label: "Start Date" },
          { value: "end_date", label: "End Date" },
        ],
      },
      {
        label: "Date Range",
        type: "date-range",
        options: [
          { value: "start_date", label: "Start Date" },
          { value: "end_date", label: "End Date" },
        ],
      },
      {
        label: "Task No",
        type: "text",
        name: "task_no",
      },
      {
        label: "Title",
        type: "text",
        name: "title",
      },
      {
        label: "Project",
        name: "project_id",
        type: "custom-select",
        componentKey: "project",
        inactive:
          actualPath === MODULES.PROJECT || actualPath === MODULES.SPRINT,
      },
      {
        label: "Sprint",
        name: "sprint_id",
        type: "custom-select",
        componentKey: "sprint",
        inactive:
          actualPath === MODULES.PROJECT || actualPath === MODULES.SPRINT,
      },
      {
        label: "Assignee",
        name: "assignee",
        type: "custom-select",
        componentKey: "users",
        inactive: IsCustomer(),
      },
      {
        label: "Collaborator",
        name: "collaborator_id",
        type: "custom-select",
        componentKey: "users",
        inactive: IsCustomer(),
      },
      {
        label: "Created By",
        name: "created_by",
        type: "custom-select",
        componentKey: "users",
        inactive: IsCustomer(),
      },
      {
        label: "Coordinator",
        name: "coordinator",
        type: "custom-select",
        componentKey: "users",
        inactive: IsCustomer(),
      },
      {
        label: "Priority",
        name: "priority",
        type: "custom-select",
        componentKey: "priority",
        inactive: IsCustomer(),
        fetchUrl: "crm/task-priority",
      },
      {
        label: "Status",
        name: "status_filter",
        type: "custom-select",
        componentKey: "task_status_multi",
        inactive: IsCustomer(),
      },
      {
        label: "Products",
        name: "product_id",
        type: "custom-select",
        componentKey: "product_multi_check",
      },
      {
        label: "Unassigned",
        type: "switch",
        name: "unassigned",
        inactive: IsCustomer(),
        columnWidth: 1,
      },
    ],
    [MODULES.TICKET]: [
      {
        label: "Date Field",
        name: "date_range_for",
        type: "custom-select",
        componentKey: "common_select",
        options: IsCustomer()
          ? [{ key: "start_date", value: "Created Date" }]
          : [
              { value: "start_date", label: "Start Date" },
              { value: "end_date", label: "End Date" },
            ],
      },
      {
        label: "Date Range",
        type: "date-range",
        options: IsCustomer()
          ? [{ key: "start_date", value: "Created Date" }]
          : [
              { value: "start_date", label: "Start Date" },
              { value: "end_date", label: "End Date" },
            ],
      },
      {
        label: "Title",
        type: "text",
        name: "title",
      },
      {
        label: "Project",
        name: "project_id",
        type: "custom-select",
        componentKey: "project",
        inactive: actualPath === MODULES.PROJECT,
      },
      {
        label: "Client",
        name: "account_id",
        type: "custom-select",
        componentKey: "account",
        inactive: IsCustomer() || actualPath === MODULES.PROJECT,
      },
      {
        label: "Type",
        name: "type",
        type: "custom-select",
        componentKey: "ticket_type",
      },
      {
        label: "Origin",
        name: "origin",
        type: "custom-select",
        componentKey: "ticket_origin",
        inactive: IsCustomer(),
      },
      {
        label: "Priority",
        name: "priority",
        type: "custom-select",
        componentKey: "priority",
        inactive: IsCustomer(),
        fetchUrl: "crm/ticket-priority-list",
      },
      {
        label: "Assignee",
        name: "assignee",
        type: "custom-select",
        componentKey: "users",
        inactive: IsCustomer() || actualPath === MODULES.EMPLOYEE,
      },
      {
        label: "Created By",
        name: "created_by",
        type: "custom-select",
        componentKey: "users",
        inactive: IsCustomer(),
      },
      {
        label: "Order By",
        name: "order_by",
        type: "custom-select",
        componentKey: "common_select",
        isClearable: false,
        options: [
          { label: "Ascending", value: "asc" },
          { label: "Descending", value: "desc" },
        ],
      },
      {
        label: "Billable",
        name: "billable",
        type: "custom-select",
        componentKey: "common_select",
        options: [
          { label: "Yes", value: "1" },
          { label: "No", value: "0" },
        ],
        inactive: IsCustomer(),
      },
      {
        label: "Status",
        name: "status",
        type: "custom-select",
        componentKey: "ticket_status",
      },
      {
        label: "Product",
        name: "product_id",
        type: "custom-select",
        componentKey: "product",
        inactive: IsCustomer(),
      },
    ],
    [MODULES.USER]: [
      {
        label: "Search",
        type: "text",
        name: "search",
      },
    ],
    [MODULES.INBOX]: [
      {
        label: "Date Field",
        name: "date_range_for",
        type: "custom-select",
        componentKey: "common_select",
        options: [
          { value: "start_date", label: "Start Date" },
          { value: "end_date", label: "End Date" },
        ],
        columnWidth: 2,
      },
      {
        label: "Date Range",
        type: "date-range",
        options: [
          { value: "start_date", label: "Start Date" },
          { value: "end_date", label: "End Date" },
        ],
        columnWidth: 2,
      },
      {
        label: "From Email",
        type: "text",
        name: "from_email",
        columnWidth: 2,
      },
      {
        label: "To Email",
        type: "text",
        name: "to_email",
        columnWidth: 2,
      },
      {
        label: "Read",
        type: "switch",
        name: "read",
        inactive: "",
        columnWidth: 1,
      },
      {
        label: "Starred",
        type: "switch",
        name: "starred",
        inactive: "",
        columnWidth: 1,
      },
    ],
    [MODULES.PRODUCTS]: [
      {
        label: "Name",
        type: "text",
        name: "name",
      },
    ],
    [MODULES.PROJECTREPORT]: [
      {
        label: "Date Field",
        name: "date_range_for",
        type: "custom-select",
        componentKey: "common_select",
        options: [
          { value: "start_date", label: "Start Date" },
          { value: "end_date", label: "End Date" },
        ],
      },
      {
        label: "Date Range",
        type: "date-range",
        options: [
          { value: "start_date", label: "Start Date" },
          { value: "end_date", label: "End Date" },
        ],
      },
      {
        label: "Project",
        name: "project_id",
        type: "custom-select",
        componentKey: "project",
      },
      {
        label: "Client",
        name: "customer_id",
        type: "custom-select",
        componentKey: "account",
        inactive: IsCustomer(),
      },
      {
        label: "Status",
        name: "status",
        type: "custom-select",
        componentKey: "project_status",
      },
    ],
    [MODULES.ANNOUNCEMENT]: [
      {
        label: "Date Field",
        name: "date_range_for",
        type: "select",
        options: [
          { value: "", label: "Select" },
          { value: "start_date", label: "Start Date" },
          { value: "end_date", label: "End Date" },
        ],
      },
      {
        label: "Date Range",
        type: "date-range",
        options: [
          { value: "start_date", label: "Start Date" },
          { value: "end_date", label: "End Date" },
        ],
      },
      {
        label: "Title",
        type: "text",
        name: "title",
        columnWidth: 2,
      },
      {
        label: "Status",
        name: "inactive",
        type: "select",
        componentKey: "common_select",
        options: [
          { label: "All", value: "" },
          { label: "Active", value: 0 },
          { label: "Inactive", value: 1 },
        ],
        columnWidth: 2,
      },
    ],

    [MODULES.INVITATION]: [
      {
        label: "Date Field",
        name: "date_range_for",
        type: "select",
        options: [
          { value: "", label: "Select" },
          { value: "start_date", label: "Start Date" },
          { value: "end_date", label: "End Date" },
        ],
      },
      {
        label: "Date Range",
        type: "date-range",
        options: [
          { value: "start_date", label: "Start Date" },
          { value: "end_date", label: "End Date" },
        ],
      },
      {
        label: "Email",
        type: "text",
        name: "to_email",
      },
      { label: "Role", type: "custom-select", componentKey: "role_id" },
    ],
    [MODULES.ROLE]: [
      {
        label: "Name",
        type: "text",
        name: "name",
      },
    ],
    [MODULES.LABELS]: [
      {
        label: "Label",
        type: "text",
        name: "label",
      },
      {
        label: "Type",
        type: "custom-select",
        name: "subject_type",
        componentKey: "labels_type",
      },
    ],
    [MODULES.LEADSOURCE]: [
      {
        label: "Name",
        type: "text",
        name: "name",
      },
    ],
    [MODULES.LEADSTATUS]: [
      {
        label: "Name",
        type: "text",
        name: "name",
      },
    ],
    [MODULES.DEPARTMENT]: [
      {
        label: "Name",
        type: "text",
        name: "name",
      },
    ],
    [MODULES.DESIGNATION]: [
      {
        label: "Name",
        type: "text",
        name: "name",
      },
    ],
    [MODULES.TICKETTYPES]: [
      {
        label: "Title",
        type: "text",
        name: "title",
      },
    ],
    [MODULES.EMAILCONFIG]: [
      {
        label: "Email",
        type: "text",
        name: "email",
      },
      {
        label: "Connection Type",
        name: "connection_type",
        type: "custom-select",
        componentKey: "common_select",
        options: [
          { label: "Incoming", value: "incoming" },
          { label: "Outgoing", value: "outgoing" },
        ],
      },
    ],
    [MODULES.EMPLOYEETASKSUMMARYREPORT]: [
      {
        label: "Date Field",
        name: "date_range_for",
        type: "custom-select",
        componentKey: "common_select",
        options: [
          { value: "start_date", label: "Start Date" },
          { value: "end_date", label: "End Date" },
        ],
      },
      {
        label: "Date Range",
        type: "date-range",
        options: [
          { value: "start_date", label: "Start Date" },
          { value: "end_date", label: "End Date" },
        ],
      },
      {
        label: "Employee",
        name: "employee_id",
        type: "custom-select",
        componentKey: "user_multi",
      },
    ],
    [MODULES.TASKDETAILEDREPORT]: [
      {
        label: "Date Field",
        name: "date_range_for",
        type: "custom-select",
        componentKey: "common_select",
        options: [
          { value: "start_date", label: "Start Date" },
          { value: "end_date", label: "End Date" },
          { value: "completed_date", label: "Completion Date" },
        ],
      },
      {
        label: "Date Range",
        type: "date-range",
        options: [
          { value: "start_date", label: "Start Date" },
          { value: "end_date", label: "End Date" },
          { value: "completed_date", label: "Completion Date" },
        ],
      },
      {
        label: "Project",
        name: "project_id",
        type: "custom-select",
        componentKey: "project",
      },
      {
        label: "Client",
        name: "customer_id",
        type: "custom-select",
        componentKey: "account",
        inactive: IsCustomer(),
      },
      {
        label: "Assignee",
        name: "assigned_to",
        type: "custom-select",
        componentKey: "user_multi",
        inactive: IsCustomer(),
      },
      {
        label: "Status",
        name: "status",
        type: "custom-select",
        componentKey: "task_status",
      },
    ],
    [MODULES.RUNNINGORCOMPLETEDTASKREPORT]: [
      {
        label: "Date Field",
        name: "date_range_for",
        type: "custom-select",
        componentKey: "common_select",
        options: [
          { value: "task_start_date", label: "Task Start Date" },
          { value: "task_end_date", label: "Task End Date" },
          { value: "timer_start_date", label: "Timer Start Date" },
          { value: "timer_end_date", label: "Timer End Date" },
        ],
      },
      {
        label: "Date Range",
        type: "date-range",
        options: [
          { value: "task_start_date", label: "Task Start Date" },
          { value: "task_end_date", label: "Task End Date" },
          { value: "timer_start_date", label: "Timer Start Date" },
          { value: "timer_end_date", label: "Timer End Date" },
        ],
      },
      {
        label: "Project",
        name: "project_id",
        type: "custom-select",
        componentKey: "project",
      },
      {
        label: "Client",
        name: "customer_id",
        type: "custom-select",
        componentKey: "account",
        inactive: IsCustomer(),
      },
      {
        label: "Employee",
        name: "employee_id",
        type: "custom-select",
        componentKey: "user_multi",
      },
      {
        label: "Status",
        name: "status",
        type: "custom-select",
        componentKey: "task_status",
      },
    ],
    [MODULES.TIMETRACKERTASKREPORT]: [
      {
        label: "Date Field",
        name: "date_range_for",
        type: "custom-select",
        componentKey: "common_select",
        options: [
          { value: "start_date", label: "Start Date" },
          { value: "end_date", label: "End Date" },
        ],
      },
      {
        label: "Date Range",
        type: "date-range",
        options: [
          { value: "start_date", label: "Start Date" },
          { value: "end_date", label: "End Date" },
        ],
      },
      {
        label: "Task Number",
        type: "text",
        name: "task_no",
      },
      {
        label: "Task Title",
        type: "text",
        name: "task_title",
      },
      {
        label: "Project",
        name: "project_id",
        type: "custom-select",
        componentKey: "project",
      },
      {
        label: "Client",
        name: "account_id",
        type: "custom-select",
        componentKey: "account",
        inactive: IsCustomer(),
      },
      {
        label: "Assignee/Contributor",
        name: "contributor_id",
        type: "custom-select",
        componentKey: "user_multi",
      },
    ],
    [MODULES.SPRINTDETAILEDREPORT]: [
      {
        label: "Date Field",
        name: "date_range_for",
        type: "custom-select",
        componentKey: "common_select",
        options: [
          { value: "start_date", label: "Start Date" },
          { value: "end_date", label: "End Date" },
        ],
      },
      {
        label: "Date Range",
        type: "date-range",
        options: [
          { value: "start_date", label: "Start Date" },
          { value: "end_date", label: "End Date" },
        ],
      },
      {
        label: "Project",
        name: "project_id",
        type: "custom-select",
        componentKey: "project",
      },
      {
        label: "Status",
        name: "status",
        type: "custom-select",
        componentKey: "sprint_status",
      },
    ],
    [MODULES.EMPLOYEETASKREPORT]: [
      {
        label: "Date Field",
        name: "date_range_for",
        type: "custom-select",
        componentKey: "common_select",
        options: [
          { value: "start_date", label: "Start Date" },
          { value: "end_date", label: "End Date" },
        ],
      },
      {
        label: "Date Range",
        type: "date-range",
        options: [
          { value: "start_date", label: "Start Date" },
          { value: "end_date", label: "End Date" },
        ],
      },
      {
        label: "Project",
        name: "project_id",
        type: "custom-select",
        componentKey: "project",
      },
      {
        label: "Client",
        name: "customer_id",
        type: "custom-select",
        componentKey: "account",
        inactive: IsCustomer(),
      },
      {
        label: "Employee",
        name: "user_id",
        type: "custom-select",
        componentKey: "users",
      },
      {
        label: "Status",
        name: "status",
        type: "custom-select",
        componentKey: "task_status",
      },
    ],
    [MODULES.EMPLOYEELEADSUMMARYREPORT]: [
      {
        label: "Employee",
        name: "employee_id",
        type: "custom-select",
        componentKey: "users",
      },
      {
        label: "Lead Source",
        name: "lead_source_id",
        type: "custom-select",
        componentKey: "lead_source",
      },
    ],
    [MODULES.LEADSDETAILEDREPORT]: [
      {
        label: "Owner",
        name: "owner_id",
        type: "custom-select",
        componentKey: "users",
      },
      {
        label: "Source",
        name: "source_id",
        type: "custom-select",
        componentKey: "lead_source",
      },
      {
        label: "Status",
        name: "lead_status",
        type: "custom-select",
        componentKey: "lead_status",
      },
      {
        label: "Priority",
        name: "priority",
        type: "custom-select",
        componentKey: "priority",
        fetchUrl: "crm/lead-priorities",
      },
    ],
    [MODULES.LEADCONVERSIONREPORT]: [
      {
        label: "Owner",
        name: "owner_id",
        type: "custom-select",
        componentKey: "users",
      },
    ],
    [MODULES.LEADSOURCESUMMARYREPORT]: [
      {
        label: "Date Range",
        type: "date-range",
        options: [
          { value: "start_date", label: "Start Date" },
          { value: "end_date", label: "End Date" },
        ],
      },
      {
        label: "Source",
        name: "lead_source_id",
        type: "custom-select",
        componentKey: "lead_source",
      },
    ],
    [MODULES.OPPORTUNITYDETAILEDREPORT]: [
      {
        label: "Date Field",
        name: "date_range_for",
        type: "custom-select",
        componentKey: "common_select",
        options: [{ value: "closing_date", label: "Closing Date" }],
      },
      {
        label: "Date Range",
        type: "date-range",
        options: [{ value: "closing_date", label: "Closing Date" }],
      },
      {
        label: "Lead",
        name: "lead_id",
        type: "custom-select",
        componentKey: "lead",
      },
      {
        label: "Client",
        name: "customer_id",
        type: "custom-select",
        componentKey: "account",
        inactive: IsCustomer(),
      },
      {
        label: "Campaign",
        name: "campaign_id",
        type: "custom-select",
        componentKey: "campaign",
      },
      {
        label: "Stage",
        name: "stage",
        type: "custom-select",
        componentKey: "opportunity_stage",
      },
    ],
    [MODULES.EMPLOYEEWORKTIMEREPORT]: [
      {
        label: "Date Range",
        type: "date-range",
        options: [
          { value: "from_date", label: "From Date" },
          { value: "to_date", label: "To Date" },
        ],
      },
      {
        label: "Employee",
        name: "user_id",
        type: "custom-select",
        componentKey: "user_multi",
      },
    ],
    [MODULES.PROJECTWORKTIMEREPORT]: [
      {
        label: "Date Range",
        type: "date-range",
        options: [
          { value: "from_date", label: "From Date" },
          { value: "to_date", label: "To Date" },
        ],
      },
      {
        label: "Project",
        name: "project_id",
        type: "custom-select",
        componentKey: "project",
      },
    ],
    [MODULES.TEAMS]: [
      {
        label: "Name",
        type: "text",
        name: "name",
      },
      {
        label: "Lead",
        name: "team_lead_id",
        type: "custom-select",
        componentKey: "users",
      },
    ],
    [MODULES.DOCUMENTTYPE]: [
      {
        label: "Name",
        type: "text",
        name: "name",
      },
      {
        label: "Type",
        type: "text",
        name: "type",
      },
    ],
  };

  const choosedFilter = (filtersConfig[accessLocation] || []).map((filter) => {
    const {
      componentKey,
      columns,
      name,
      options,
      isClearable,
      fetchUrl,
      ...rest
    } = filter;
    return {
      ...rest,
      name: name ?? componentKey,
      value: filterFields?.[filter.name],
      fetchUrl: filterFields?.[filter?.fetchUrl],
      options: options,
      isClearable: isClearable,
      onChange: (e) => handleFieldChange(e),
      component: componentKey
        ? getCustomComponent(
            componentKey,
            name,
            filterFields?.[`${name ?? componentKey}_ref`],
            handleFieldChange,
            options,
            isClearable,
            fetchUrl
          )
        : undefined,
    };
  });

  return choosedFilter;
};

export default getInitializedFilters;
