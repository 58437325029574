import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Badge } from "react-bootstrap";
import { createSearchParams, Link, useNavigate } from "react-router-dom";
import { RedirectButton } from "module/common/Buttons/AppButtons";
import { apiCall } from "helpers/apiCalls";
import ListLoading from "module/common/ListLoading";
import getPriorityWithProps from "module/common/helpers/getPriorityWithProps";

const PendingTaskPopup = () => {
  const [loading, setLoading] = useState(false);
  const [pendingTasks, setPendingTasks] = useState({
    task_numbers: [],
  });
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const getPendingTasks = async () => {
    setLoading(true);
    try {
      const data = await apiCall({
        url: "crm/notification-for-pending-tasks",
      });
      setPendingTasks(data);
      if (data?.total > 0) {
        setShow(true);
        localStorage.setItem("pending_task_data", JSON.stringify(data));
        localStorage.setItem("lastApiCallTime", Date.now());
        localStorage.setItem("show_pending_task_modal", JSON.stringify(true));
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching pending tasks:", error);
    }
  };

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("pending_task_data"));
    const lastApiCallTime = JSON.parse(localStorage.getItem("lastApiCallTime"));

    if (storedData) {
      setPendingTasks(storedData);
      setShow(true);
    }

    // Check if 24 hours have passed since the last API call
    if (
      lastApiCallTime &&
      Date.now() - lastApiCallTime >= 24 * 60 * 60 * 1000
    ) {
      getPendingTasks();
    }
  }, []);

  const taskNumbers = pendingTasks?.task_numbers.join(", ");

  const handleNavigate = () => {
    navigate(
      `/tasks/list?${createSearchParams({
        task_no: taskNumbers,
      })}`
    );
    setShow(false);
    localStorage.setItem("show_pending_task_modal", JSON.stringify(false));
  };

  const showModal = JSON.parse(localStorage.getItem("show_pending_task_modal"));

  return (
    <>
      <Modal
        show={show && showModal}
        backdrop="static"
        keyboard={false}
        centered
        onHide={() => {
          localStorage.setItem(
            "show_pending_task_modal",
            JSON.stringify(false)
          );
          setShow(false);
        }}
      >
        <Modal.Header
          closeButton
          className="text-dark d-flex justify-content-between"
        >
          <div
            style={{ fontSize: "16px", width: "100%" }}
            className="d-flex align-items-center justify-content-between"
          >
            You have pending tasks (with no action taken) for a week.
          </div>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <ListLoading style={{ maxHeight: "9vh", overflow: "hidden" }} />
          ) : (
            <Row className=" px-3 gap-3">
              <Col className="p-0">
                <Badge
                  className="d-flex justify-content-center fs--1 p-3"
                  bg="none"
                  style={{ backgroundColor: getPriorityWithProps("High") }}
                >
                  {pendingTasks?.high ? pendingTasks?.high : 0} High
                </Badge>
              </Col>
              <Col className="p-0">
                <Badge
                  className="d-flex justify-content-center fs--1 p-3"
                  bg="none"
                  style={{ backgroundColor: getPriorityWithProps("Medium") }}
                >
                  {pendingTasks?.medium ? pendingTasks?.medium : 0} Medium
                </Badge>
              </Col>
              <Col className="p-0">
                <Badge
                  className="d-flex justify-content-center fs--1 p-3"
                  bg="none"
                  style={{ backgroundColor: getPriorityWithProps("Low") }}
                >
                  {pendingTasks?.low ? pendingTasks?.low : 0} Low
                </Badge>
              </Col>
              {pendingTasks?.critical > 0 && (
                <Col className="p-0">
                  <Badge
                    className="d-flex justify-content-center fs--1 p-3"
                    bg="none"
                    style={{
                      backgroundColor: getPriorityWithProps("critical"),
                    }}
                  >
                    {pendingTasks?.critical ? pendingTasks?.critical : 0}{" "}
                    critical
                  </Badge>
                </Col>
              )}
            </Row>
          )}
        </Modal.Body>
        <Modal.Footer className="justify-content-end">
          <Link
            className="like-text d-flex align-items-baseline gap-1"
            onClick={handleNavigate}
            to={`/tasks/list?${createSearchParams({
              task_no: taskNumbers,
            })}`}
          >
            View pending tasks
            <RedirectButton onClick={handleNavigate} />
          </Link>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PendingTaskPopup;
