import React, { useContext, useEffect, useState } from "react";
import { Badge, Card, ListGroup, Offcanvas, Tab, Tabs } from "react-bootstrap";
import { FaBell } from "react-icons/fa";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./TicketInfo.scss";
import ProfileImage from "../../../assets/img/Avathar/profile.png";
import ProfileImageShe from "../../../assets/img/Avathar/she.jpg";
import Image from "react-bootstrap/Image";
import Attachments from "module/common/ViewPages/Common/Attachments/Attachments";
import { apiCall } from "helpers/apiCalls";
import ListLoading from "module/common/ListLoading";
import { TbStatusChange } from "react-icons/tb";
import axios from "axios";
import { showToast } from "module/common/Toast/toast";
import Comments from "module/common/ViewPages/Common/Comments/Comments";
import ActivityTimeLine from "module/common/ViewPages/Common/Activity/Timeline/ActivityTimeLine";
import {
  ConvertButton,
  CustomBackButton,
  CustomEditButton,
  RedirectButton,
} from "module/common/Buttons/AppButtons";
import { BiComment, BiSupport } from "react-icons/bi";
import { AuthWizardContext, StoreContext } from "context/Context";
import { SiOrigin } from "react-icons/si";
import { MdAttachFile, MdOutlineDateRange } from "react-icons/md";
import { RxActivityLog } from "react-icons/rx";
import useAxisproTranslate from "hooks/useAxisproTranslate";
import RibbonButtons from "module/common/Buttons/RibbonButtons";
import getPriorityWithProps from "module/common/helpers/getPriorityWithProps";
import CustomerDetails from "components/customer-details/CustomerDetails";
import UserProfile from "module/common/Offcanvas/UserProfile/UserProfile";
import { GetAcodaxPermission } from "module/common/Permissions/AcodaxPermission";
import TicketForm from "../TicketForm/TicketForm";
import { stringLimiter } from "helpers/utils";
import UpdateAssigneeListDropDown from "components/common/DropDown/UpdateAssigneeListDropDown";
import ProjectOffcanvas from "module/Project/ProjectOffcanvas/ProjectOffcanvas";

const TicketInfo = ({ show, id, onHide, getDataRefresh, data }) => {
  const Translate = useAxisproTranslate();
  let navigate = useNavigate();
  let user = useContext(AuthWizardContext);
  let loggedAsCustomer = user?.user?.customer_id ? true : false;
  const location = useLocation();
  const containsProject = location.pathname.includes("project/info");
  let { itemId } = useParams();
  itemId = itemId ? itemId : id ? id : "";
  const [loading, setLoading] = useState(false);
  const [ticketData, setTicketData] = useState({});
  const [ticketStatus, setTicketStatus] = useState([]);
  const [key, setKey] = useState("comments");
  const [showTicketForm, setShowTicketForm] = useState(false);
  const permission = {
    update: GetAcodaxPermission("TICKET", "update"),
    updateStatus: GetAcodaxPermission("TICKET_STATUS", "update"),
    showProject: GetAcodaxPermission("PROJECT", "show"),
    showCustomer: GetAcodaxPermission("CUSTOMER", "show"),
  };

  const [isLoading, setIsLoading] = useState(false);
  const [onSave, setOnSave] = useState(false);
  const [customerData, setCustomerData] = useState({
    showCustomerInfo: false,
    value: "",
    label: "",
  });
  const [assigneeData, setAssigneeData] = useState({
    show: false,
    id: "",
  });
  const [projectInfo, setProjectInfo] = useState({
    show: false,
    id: "",
  });
  const [showConvertButton, setShowConvertButton] = useState(false);
  const { store, addIitemToStore } = useContext(StoreContext);

  const handleClose = () => {
    if (show) {
      onHide();
      setKey("comments");
    } else {
      navigate(-1);
      setKey("comments");
    }
  };

  useEffect(() => {
    if ((itemId || id) && show && !data) {
      getDataItem();
    }
    if (data) {
      setTicketData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId, id]);

  const getDataItem = async () => {
    setLoading(true);
    const data = await apiCall({
      url: `crm/ticket/${id ? id : itemId}`,
    });
    setTicketData(data);
    if (data?.is_converted === 0 && !loggedAsCustomer) {
      setShowConvertButton(true);
    }
    setLoading(false);
  };

  const handleTicketStatus = (status) => {
    if (onSave) return;
    if (
      status.value &&
      status.value !== ticketData.status &&
      permission?.updateStatus
    ) {
      setOnSave(true);
      axios({
        method: "post",
        url: `crm/ticket-status/update`,
        params: {
          ticket_id: ticketData?.id ? ticketData?.id : itemId,
          status: status.value,
        },
      }).then((response) => {
        if (response.data.success === true) {
          setTicketData(response.data.data);
          showToast(response.data.message, "success");
          setOnSave(false);
          handleGetData();
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
          setOnSave(false);
        }
      });
    }
  };

  const getData = async () => {
    setIsLoading(true);
    const data = await apiCall({
      url: "crm/ticket-status",
    });
    addIitemToStore("ticketStatus", data);
    setTicketStatus(data);
    setIsLoading(false);
  };

  useEffect(() => {
    if (show && store?.ticketStatus?.length === 0) {
      getData();
    } else {
      setTicketStatus(store?.ticketStatus);
    }

    return () => {
      setShowConvertButton(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const updateAssigneeURL = `crm/ticket/${id ? id : itemId}/update-assignee`;

  const handleGetData = () => {
    getDataItem();
    getDataRefresh();
  };

  const formattedStatus = ticketStatus?.map((item) => ({
    label:
      item === "awaiting_business_input"
        ? "Await"
        : item === "with_client_review"
        ? "Review"
        : item,
    value: item,
  }));

  const dataToRibbonButton = {
    id: ticketData?.id,
    getData: getData,
    handleSelect: handleTicketStatus,
    ribbonData: formattedStatus,
    isLoading: isLoading,
    itemRef: {
      value: ticketData?.status,
      label:
        ticketData?.status === "awaiting_business_input"
          ? "Await"
          : ticketData?.status === "with_client_review"
          ? "Review"
          : ticketData?.status,
    },
    IconComponent: TbStatusChange,
  };

  const handleNavigation = (type) => {
    if (type === "project" && permission?.showProject) {
      setProjectInfo({
        show: true,
        id: ticketData?.project_id,
      });
    } else if (type === "client" && permission?.showCustomer) {
      setCustomerData({
        showCustomerInfo: !customerData?.showCustomerInfo,
        value: ticketData?.customer_id,
        label: ticketData?.customer_name,
      });
    } else if (type === "assignee") {
      setAssigneeData({
        show: true,
        id: ticketData?.assigned_to,
      });
    }
  };

  const fetchData = handleGetData;

  return (
    <Offcanvas
      show={show}
      placement="end"
      onHide={handleClose}
      style={{ width: "700px" }}
      className={`${
        customerData?.showCustomerInfo ||
        assigneeData?.show ||
        projectInfo?.show
          ? "d-none"
          : ""
      }`}
    >
      <Offcanvas.Header className="border-bottom">
        <Offcanvas.Title className="d-flex flex-wrap justify-content-between align-items-center gap-2 w-100">
          <div className="d-flex align-items-center justify-content-between gap-2">
            <BiSupport size={22} className="me-1 text-dark" />
            <span className="fs-1">{Translate("Ticket Overview")}</span>
            <span>
              {!containsProject && (
                <RedirectButton
                  route={`/tickets/list/overview/${id ? id : itemId}`}
                />
              )}
            </span>
          </div>
          <div className="d-flex flex-wrap align-items-center justify-content-start gap-2">
            {showConvertButton && (
              <ConvertButton
                onClick={() =>
                  navigate(`/tickets/list/convert/${id ? id : itemId}`)
                }
                title="Convert To Task"
              />
            )}
            {permission?.update && (
              <CustomEditButton
                variant="light"
                onClick={() => setShowTicketForm(true)}
                label="Edit Ticket"
                className="shadow ms-1"
              />
            )}
            <CustomBackButton
              variant="falcon-default"
              title="Back"
              onClick={handleClose}
            />
          </div>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {!loading && Object.keys(ticketData).length > 0 ? (
          <>
            <Card>
              <Card.Header
                className="rounded-0 ticket-custom-overview-card-header"
                style={{
                  backgroundColor:
                    ticketData?.priority === "high" ? "#ff00000f" : "#fff",
                }}
              >
                <div className="d-grid">
                  <span
                    className="fw-bold text-capitalize"
                    style={{ display: "flex", flexWrap: "wrap" }}
                  >
                    <div>
                      <span>
                        <FaBell size={18} color="#8b8989" className="me-2" />
                        {ticketData?.title}
                      </span>
                    </div>
                    <div className="ms-auto">
                      {ticketData?.reference && (
                        <Badge
                          bg={"info"}
                          className="me-1"
                          style={{ fontSize: "11px" }}
                        >
                          {ticketData?.reference}
                        </Badge>
                      )}
                      <Badge
                        bg={"none"}
                        style={{
                          backgroundColor: getPriorityWithProps(
                            ticketData?.priority
                          ),
                          fontSize: "11px",
                        }}
                        className="me-1"
                      >
                        {ticketData?.priority}
                      </Badge>
                      <Badge
                        bg={"none"}
                        style={{
                          backgroundColor:
                            ticketData?.billable === 1 ? "#139101" : "#FC859D",
                          fontSize: "11px",
                        }}
                      >
                        {ticketData?.billable === 1
                          ? "Billable"
                          : "Non Billable"}
                      </Badge>
                    </div>
                  </span>
                  <span className="pt-2 text-dark">
                    {ticketData?.description}
                  </span>
                </div>
              </Card.Header>
              <Card.Body className="border rounded-0 p-0">
                <ListGroup key="md" horizontal="md" className="d-flex">
                  <ListGroup.Item className="rounded-0 border-0 border-end flex-fill">
                    <div
                      className="d-grid gap-1 mb-2 cursor-pointer"
                      onClick={() => {
                        handleNavigation("project");
                      }}
                    >
                      <span className="custom-text-muted-crm fw-bold">
                        {Translate("PROJECT")}
                      </span>
                      <span className="fw-bold text-capitalize link-like-text">
                        {stringLimiter(ticketData?.project, 45)}
                      </span>
                    </div>
                  </ListGroup.Item>
                  {!loggedAsCustomer && (
                    <ListGroup.Item className="rounded-0 border-0 border-end flex-fill">
                      <div
                        className="d-grid gap-1 mb-2 cursor-pointer"
                        onClick={() => {
                          handleNavigation("client");
                        }}
                      >
                        <span className="custom-text-muted-crm fw-bold">
                          {Translate("Client")}
                        </span>
                        <span className="fw-bold text-capitalize link-like-text">
                          <Image
                            className="custom-image-crm"
                            src={
                              ticketData?.customer_image
                                ? ticketData?.customer_image
                                : ProfileImage
                            }
                            roundedCircle
                            alt="?"
                            loading="lazy"
                            height={"30vh"}
                            width={"30vh"}
                          />
                          <span className="ms-1">
                            {ticketData?.customer_name ?? ""}
                          </span>
                        </span>
                      </div>
                    </ListGroup.Item>
                  )}
                  {!loggedAsCustomer && (
                    <ListGroup.Item className="rounded-0 border-0 border-end flex-fill">
                      <div className="d-grid gap-1 mb-2 cursor-pointer">
                        <span className="custom-text-muted-crm fw-bold d-flex gap-1 align-items-center">
                          {Translate("Assignee")}{" "}
                          {permission?.update && (
                            <div style={{ marginTop: "-4px" }}>
                              <UpdateAssigneeListDropDown
                                url={updateAssigneeURL}
                                getData={handleGetData}
                                active={{
                                  label: ticketData?.assignee,
                                  value: ticketData?.assigned_to,
                                  image: ticketData?.assignee_image,
                                }}
                                itemId={ticketData?.id}
                                method={"patch"}
                              />
                            </div>
                          )}
                        </span>
                        <span
                          className="fw-bold text-capitalize link-like-text"
                          onClick={() => {
                            handleNavigation("assignee");
                          }}
                        >
                          {ticketData?.assigned_to && (
                            <Image
                              className="custom-image-crm"
                              src={
                                ticketData?.assignee_image
                                  ? ticketData?.assignee_image
                                  : ProfileImageShe
                              }
                              roundedCircle
                              alt="?"
                              loading="lazy"
                              height={"30vh"}
                              width={"30vh"}
                            />
                          )}
                          <span
                            className={`${
                              ticketData?.assigned_to ? "ms-1" : ""
                            }`}
                          >
                            {ticketData?.assigned_to
                              ? ticketData?.assignee
                              : "Unassigned"}
                          </span>
                        </span>
                      </div>
                    </ListGroup.Item>
                  )}
                  <ListGroup.Item className="rounded-0 border-0 border-end flex-fill">
                    <div className="d-grid gap-1 mb-2">
                      <span className="custom-text-muted-crm fw-bold">
                        {Translate("Ticket Type")}
                      </span>
                      <span className="fw-bold text-capitalize text-dark">
                        {ticketData?.ticket_type}
                      </span>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item className="rounded-0 border-0 border-end flex-fill">
                    <div className="d-grid gap-1 mb-2">
                      <span className="custom-text-muted-crm fw-bold">
                        {Translate("Product")}
                      </span>
                      <span className="fw-bold text-capitalize text-dark">
                        {ticketData?.product_title ?? "N/A"}
                      </span>
                    </div>
                  </ListGroup.Item>
                </ListGroup>
              </Card.Body>
            </Card>
            <div className="my-3 mx-2">
              <RibbonButtons dataToRibbonButton={dataToRibbonButton} />
            </div>
            <div className="d-flex justify-content-between">
              <div className="pb-2">
                <Badge bg="info" className="d-flex align-items-center">
                  <SiOrigin size={16} className="me-1" />
                  <span>{Translate("Ticket Orign")}: </span>
                  <span className="fs--2 text-capitalize ms-1">
                    {ticketData?.ticket_origin}
                  </span>
                </Badge>
              </div>
              <div className="pb-2">
                <Badge
                  bg="none"
                  style={{ backgroundColor: "#387F39" }}
                  className="d-flex align-items-center"
                >
                  <MdOutlineDateRange size={16} className="me-1" />
                  <span>{Translate("Last Activity")}: </span>
                  <span className="fs--2 ms-1">{ticketData?.updated_at}</span>
                </Badge>
              </div>
            </div>
            <div className="ticket-tabs-container">
              <Tabs
                defaultActiveKey="comments"
                className="mb-3 custom-lead-offcanvas-header-tabs"
                fill
                variant="tabs"
                activeKey={key}
                onSelect={(k) => setKey(k)}
              >
                <Tab
                  eventKey="comments"
                  title={
                    <div className="d-flex align-items-center justify-content-center">
                      <BiComment size={14} color="#d68c45" />
                      <span className="ms-1 fw-bold">
                        {Translate("Comments")}
                      </span>
                    </div>
                  }
                  className="ticket-custom-tab ticket-custom-tab-overview"
                >
                  {key === "comments" && (
                    <Comments id={id ? id : itemId} comment_type="ticket" />
                  )}
                </Tab>
                {!loggedAsCustomer && (
                  <Tab
                    eventKey="activity"
                    title={
                      <div className="d-flex align-items-center justify-content-center">
                        <RxActivityLog size={12} color="#B692C2" />{" "}
                        <span className="ms-2 fw-bold">
                          {Translate("Activity")}
                        </span>
                      </div>
                    }
                    className="ticket-custom-tab ticket-custom-tab-overview"
                  >
                    {key === "activity" && (
                      <ActivityTimeLine itemId={id ? id : itemId} />
                    )}
                  </Tab>
                )}
                <Tab
                  eventKey="attachments"
                  title={
                    <div className="d-flex align-items-center justify-content-center">
                      <MdAttachFile size={14} color="#3FA2F6" />{" "}
                      <span className="ms-1 fw-bold">
                        {Translate("Attachments")}
                      </span>
                    </div>
                  }
                  className="ticket-custom-tab ticket-custom-tab-overview"
                >
                  {key === "attachments" && (
                    <Attachments
                      itemId={ticketData.id}
                      type="TICKET"
                      commentsList={ticketData}
                      fetchUrl={`crm/ticket-attachments/${ticketData.id}`}
                    />
                  )}
                </Tab>
              </Tabs>
            </div>
          </>
        ) : (
          <ListLoading />
        )}
      </Offcanvas.Body>

      <CustomerDetails
        show={customerData?.showCustomerInfo}
        onHide={() => {
          setCustomerData({ showCustomerInfo: false, value: "", label: "" });
        }}
        value={customerData}
        showCustomerDetailsArea={customerData?.showCustomerInfo}
      />
      <UserProfile
        show={assigneeData?.show}
        handleClose={() =>
          setAssigneeData({
            show: false,
            id: "",
          })
        }
        id={assigneeData?.id}
      />
      {showTicketForm && (
        <TicketForm
          show={showTicketForm}
          handleCloseForm={() => setShowTicketForm(false)}
          openFrom="ticket_offcanvas"
          ticketId={itemId}
          fetchData={fetchData}
        />
      )}
      {projectInfo?.show && (
        <ProjectOffcanvas
          show={projectInfo?.show}
          id={projectInfo?.id}
          onHide={() => setProjectInfo({ show: false, id: "" })}
        />
      )}
    </Offcanvas>
  );
};

export default TicketInfo;
